import { useMemo } from "react";

const USERS_REQUIRING_DIGITIZATION = new Set([
    '51', // Acindar
    '142', // Seller prueba
]);

interface UseShouldDigitizeInvoiceParams {
    userId: string;
}

const useShouldDigitizeInvoice = ({ userId }: UseShouldDigitizeInvoiceParams): boolean => {
    return useMemo(() => USERS_REQUIRING_DIGITIZATION.has(userId), [userId]);
};

export default useShouldDigitizeInvoice;
