import { useState, useEffect } from 'react';
import { DeliveryContext } from '../../contexts/deliveryContext';
import {
	Alert,
	Button,
	Grid,
	Typography,
	debounce,
	styled,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@mui/material';
import StatusCard from '../status-card';
import { useParams } from 'react-router-dom';
import { sellerStatuses } from './statuses';
import {
	Response,
	FilesList,
	DeliveriesList,
	Document,
	SellerListDetails,
	QuoteStatusForSeller,
	Delivery
} from '../../types';
import { useIntl } from 'react-intl';
import { ExpandMore, Upload } from '@mui/icons-material';
import Card from '../card';
import client from '../../clients/client';
import FullScreenLoading from '../full-screen-loading';
import FileWithDetails from '../file-with-details';
import EmptySupportedTypography from '../empty-supported-typography';
import { relatedDeliveries, relatedFiles, relatedDeliveryFiles } from './columns';
import FileUploadDialog from './dialogs/file-upload-dialog';
import SellerNewDeliveryDialog from './deliveries/seller-new-delivery-dialog';
import TableCardTreeView from '../table-card-tree-view';
import SellerRejectionDialog from './dialogs/seller-rejection-dialog';
import SellerExtensionDialog from './dialogs/seller-extension-dialog';
import SellerQuotationDialog from './dialogs/seller-quotation-dialog';
import QuotingConditions from './dialogs/seller-quoting-conditions';
import Error from '../error';
import PageContainer from '../page-container';
import DeliveryAddress from '../delivery-address';
import userState from '../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import ClientInfoComponent from '../client-info';

const StyledActionButton = styled(Button)(({ theme }) => ({
	width: '150px',
	margin: '5px',
	'&:hover': {
		backgroundColor: '#999',
		color: 'white',
		border: 'none!important',
	},
}));

const cancelationReasons = [
	{
		cancellation_id: 1,
		cancellation_type: 'No comercializo los productos',
	},
	{
		cancellation_id: 5,
		cancellation_type: 'No hago envíos a esa zona',
	},
	{
		cancellation_id: 4,
		cancellation_type: 'Otros',
	},
];

const DetailsSeller = () => {
	const { listId, quoteId } = useParams();
	const user = useRecoilValue(userState);

	const intl = useIntl();

	const [message, setMessage] = useState<any>();
	const [deliveriesCount, setDeliveriesCount] = useState<number>();
	const [baseInfo, setBaseInfo] = useState<any>();

	const [quotationDialogOpen, setQuotationDialogOpen] = useState(false);
	const [extensionDialogOpen, setExtensionDialogOpen] = useState(false);
	const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [newDeliveryDialogOpen, setNewDeliveryDialogOpen] = useState(false);
	const [listDetailsResponse, setListDetailsResponse] = useState<Response<SellerListDetails>>();
	const [filesResponse, setFilesResponse] = useState<Response<FilesList>>();
	const [deliveriesResponse, setDeliveriesResponse] = useState<Response<DeliveriesList>>();
	const [allDocuments, setAllDocuments] = useState<Document[]>([]);
	const [allDeliveries, setAllDeliveries] = useState<Delivery[]>([]);
	const [extraPush, setExtraPush] = useState<boolean>(false);
	const [conditionsOpen, setConditionsOpen] = useState<boolean>(false);

	const fetchData = debounce(() => {
		setListDetailsResponse(undefined);

		client
			.getSeller({ listId, quoteId, user })
			.then((data) => {
				setListDetailsResponse({ data });
				setBaseInfo({ metadata: data?.metadata, quotation: data?.quotation });
				fetchListDocuments(data?.quotation.order_id);
				fetchListDeliveries(data?.quotation.order_id);
			})
			.catch(() => setListDetailsResponse({ error: true }));
	}, 250);

	const fetchListDeliveries = debounce((order_id) => {
		setDeliveriesResponse(undefined);
		setAllDeliveries([]);

		client
			.getListDeliveries({ orderId: order_id, user })
			.then((data) => {
				setDeliveriesResponse({ data });
				const delivs = data?.map((delivery: Delivery) => delivery);
				setAllDeliveries(delivs ?? []);
				setDeliveriesCount(delivs.filter((del: Delivery) => del.status === 1).length);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	const fetchListDocuments = debounce((order_id) => {
		setFilesResponse(undefined);

		client
			.getSellerListDocuments({ listId, orderId: order_id, user })
			.then((data) => {
				setFilesResponse({ data });
				const filteredDocs = data?.map((file: Document) => file);
				setAllDocuments(filteredDocs ?? []);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (message) {
			fetchListDeliveries(baseInfo?.quotation.order_id);
			setMessage(undefined);
		}
	}, [message]);

	let allowedFileTypes = [
		{ id: 6, name: 'Factura' },
		{ id: 13, name: 'Nota de crédito' },
		{ id: 15, name: 'Nota de débito' },
		{ id: 14, name: 'Recibo de pago' },
	];

	if (!listDetailsResponse) {
		return <FullScreenLoading />;
	}

	const { data, error } = listDetailsResponse;
	if (error || !data) {
		return <Error />;
	}

	const { quotation, metadata, files, delivery } = data;

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = allDocuments;

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setAllDocuments(allNewDocs);
		} else {
			setAllDocuments([...allDocuments, newFile]);
		}
	};

	return (
		<PageContainer>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<StatusCard
						title={intl.formatMessage(
							{ id: 'list.seller.details.statuses.title' },
							{
								listId: metadata.list_migration_id,
							}
						)}
						quotationId={metadata.list_migration_id}
						orderStatus={
							quotation.status === QuoteStatusForSeller.IN_PURCHASE_PROCESS ? quotation.order_status : undefined
						}
						titleLocation="center"
						subtitleLines={
							quotation.status === QuoteStatusForSeller.PENDING_QUOTATION ||
							quotation.status === QuoteStatusForSeller.AWAITING_FOR_RESPONSE
								? 1
								: 2
						}
						leftSubtitle={
							quotation.status === QuoteStatusForSeller.PENDING_QUOTATION ||
							quotation.status === QuoteStatusForSeller.AWAITING_FOR_RESPONSE ||
							quotation.status === 'No concretada' ? (
								<Box sx={{ mt: 1 }}>
									<Typography variant="subtitle1" fontWeight="bold" display="inline" mr={.6}>
										{intl.formatMessage({ id: 'list.seller.details.statuses.leftSubtitle1' })}
									</Typography>
									<EmptySupportedTypography variant="subtitle1" display="inline" color="#7E7E82">
										{quotation.buyer || quotation.business_social || quotation.constructor_business_name}
									</EmptySupportedTypography>
								</Box>
							) : (
								<Box>
									<Box sx={{ mt: 1, display: 'flex' }}>
										<Typography variant="subtitle1" fontWeight="bold" display="inline" mr={.6}>
											{intl.formatMessage({ id: 'list.seller.details.statuses.leftSubtitle1' })}
										</Typography>
										&nbsp;<ClientInfoComponent businessNameId={quotation.business_id} constructor_id={quotation.constructor_id} />
									</Box>
									<Box sx={{ mt: 1 }}>
										<EmptySupportedTypography variant="subtitle1" display="inline" color="#7E7E82">
											<DeliveryAddress
												address={quotation.address ?? ''}
												locality={quotation.locality}
												user={user}
												sx={quotation.address ? { justifyContent: 'start' } : {}}
											></DeliveryAddress>
										</EmptySupportedTypography>
									</Box>
								</Box>
							)
						}
						rightSubtitle={
							quotation.status === QuoteStatusForSeller.PENDING_QUOTATION ||
							quotation.status === QuoteStatusForSeller.AWAITING_FOR_RESPONSE ||
							quotation.status === 'No concretada' ? (
								<Box sx={{ mt: 1 }}>
									{/* <Typography variant="subtitle1" fontWeight="bold" display="inline">
										{intl.formatMessage({ id: 'list.seller.details.statuses.leftSubtitle2' })}
									</Typography> */}
									<EmptySupportedTypography variant="subtitle1" display="inline" color="#7E7E82">
										<DeliveryAddress
											address={quotation.address ?? ''}
											locality={quotation.locality}
											user={user}
										></DeliveryAddress>
									</EmptySupportedTypography>
								</Box>
							) : (
								<Box>
									<Box sx={{ mt: 1 }}>
										<Typography variant="subtitle1" fontWeight="bold" display="inline">
											{intl.formatMessage({ id: 'list.seller.details.statuses.rightSubtitle1' })}
										</Typography>
										<EmptySupportedTypography variant="subtitle1" display="inline" color="#7E7E82">
											{quotation.business_social || quotation.constructor_business_name}
										</EmptySupportedTypography>
									</Box>
									<Box sx={{ mt: 1 }}>
										<Typography variant="subtitle1" fontWeight="bold" display="inline">
											{intl.formatMessage({ id: 'list.seller.details.statuses.rightSubtitle2' })}
										</Typography>
										<EmptySupportedTypography variant="subtitle1" display="inline" color="#7E7E82">
											{quotation.business_cuit ||quotation.constructor_cuit}
										</EmptySupportedTypography>
									</Box>
								</Box>
							)
						}
						statuses={sellerStatuses.map((status) => ({
							label: status.id,
							warning: status.getWarning && status.getWarning(data),
							active: status.isActive(data),
							completed: status.isCompleted(data),
						}))}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						title={
							!metadata?.requote_request || metadata?.requote_cause_id !== '1'
								? intl.formatMessage({ id: 'list.seller.details.estimation.title' })
								: intl.formatMessage({ id: 'list.seller.details.estimation.last_sent' })
						}
						footer={
							(quotation?.status === QuoteStatusForSeller.NOT_COMPLETED_SALE && quotation.lost_cause && (
								<>
									<Typography textAlign="center" sx={{ color: '#FFF' }}>
										{intl.formatMessage({ id: 'list.seller.details.estimation.lost' })}
									</Typography>
									<Typography textAlign="center" sx={{ color: '#FFF' }}>
										{intl.formatMessage(
											{ id: 'list.seller.details.estimation.lost_cause' },
											{ cause: quotation.lost_cause }
										)}
									</Typography>
								</>
							)) ||
							(quotation?.status === QuoteStatusForSeller.AWAITING_FOR_RESPONSE && !metadata?.requote_request && (
								<>
									{!metadata?.requote_cause_id &&
										(metadata?.max_version_quotation === 1 || !metadata?.max_version_quotation) && (
											<Box sx={{ px: { md: 8, xs: 2 } }}>
												<Typography textAlign="center" sx={{ color: '#FFF' }}>
													{intl.formatMessage({
														id: 'list.seller.details.estimation.awaiting_customer_response_to_quotation_message',
													})}
												</Typography>
											</Box>
										)}
									{!metadata?.requote_request &&
										metadata?.requote_cause_id !== '1' &&
										metadata?.max_version_quotation > 1 && (
											<Box sx={{ px: { md: 8, xs: 2 } }}>
												<Typography textAlign="center" sx={{ color: '#FFF' }}>
													{intl.formatMessage({
														id: 'list.seller.details.estimation.awaiting_customer_response_to_re_quotation_message',
													})}
												</Typography>
											</Box>
										)}
									{!metadata?.requote_request &&
										metadata?.requote_cause_id === '1' &&
										metadata?.max_version_quotation > 1 && (
											<Box sx={{ px: { md: 8, xs: 2 } }}>
												<Typography textAlign="center" sx={{ color: '#FFF' }}>
													{intl.formatMessage({
														id: 'list.seller.details.estimation.awaiting_customer_response_to_extension_message',
													})}
												</Typography>
											</Box>
										)}
								</>
							))
						}
					>
						<Grid container sx={{ height: '100%', alignItems: 'center' }}>
							{files && (
								<>
									{(!metadata?.requote_request || metadata?.requote_cause_id !== '1') && (
										<FileWithDetails
											title={intl.formatMessage({ id: 'list.seller.details.estimation.quotation_title' })}
											listId={metadata?.list_id}
											user={user}
											driveFileId={files.quotation_request_file.id}
											gcsFilePath={files.quotation_request_file.gcs_file_path}
											details={
												quotation.status !== QuoteStatusForSeller.NOT_COMPLETED_SALE
													? [QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(
															quotation.status
													  )
														? [
																{
																	key: intl.formatMessage({ id: 'list.seller.details.estimation.date' }),
																	value:
																		files.quotation_request_file.date_loaded &&
																		intl.formatDate(files.quotation_request_file.date_loaded, { timeZone: 'UTC' }),
																},
																{
																	key: intl.formatMessage({ id: 'list.seller.details.estimation.deadline' }),
																	value:
																		files.quotation_request_file.date_due &&
																		intl.formatDate(files.quotation_request_file.date_due, { timeZone: 'UTC' }),
																},
																{
																	key: '¿Cotizar envío?',
																	value: 'Sí',
																},
														  ]
														: [
																{
																	key: intl.formatMessage({ id: 'list.seller.details.estimation.date' }),
																	value:
																		files.quotation_request_file.date_loaded &&
																		intl.formatDate(files.quotation_request_file.date_loaded, { timeZone: 'UTC' }),
																},
																{
																	key: intl.formatMessage({ id: 'list.seller.details.estimation.deadline' }),
																	value:
																		files.quotation_request_file.date_due &&
																		intl.formatDate(files.quotation_request_file.date_due, { timeZone: 'UTC' }),
																},
														  ]
													: [
															{
																key: intl.formatMessage({ id: 'list.seller.details.estimation.date' }),
																value:
																	files.quotation_request_file.date_loaded &&
																	intl.formatDate(files.quotation_request_file.date_loaded, { timeZone: 'UTC' }),
															},
													  ]
											}
										/>
									)}
									{[QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) &&
										metadata?.requote_request &&
										metadata?.requote_cause_id === '1' && (
											<FileWithDetails
												title={intl.formatMessage({ id: 'Última cotizacion enviada' })}
												listId={metadata?.list_id}
												user={user}
												driveFileId={files.last_sent_quotation.id}
												gcsFilePath={files.last_sent_quotation.gcs_file_path}
												details={[
													{
														key: 'Fecha de cotización:',
														value:
															files.last_sent_quotation.date_loaded &&
															intl.formatDate(files.last_sent_quotation.date_loaded, { timeZone: 'UTC' }),
													},
													{
														key: 'Fecha de vigencia:',
														value:
															files.last_sent_quotation.date_due &&
															intl.formatDate(files.last_sent_quotation.date_due, { timeZone: 'UTC' }),
													},
													{
														key: 'Condiciones ofrecidas:',
														value: (
															<>
																<Box
																	onClick={() => setConditionsOpen(true)}
																	sx={{
																		width: '100%',
																		cursor: 'pointer',
																		fontSize: '1em',
																		color: '#1737B7',
																	}}
																>
																	Ver
																</Box>
																<QuotingConditions
																	open={conditionsOpen}
																	conditions={files.last_sent_quotation.offered_coditions}
																	onClose={() => setConditionsOpen(false)}
																/>
															</>
														),
													},
												]}
											/>
										)}
									{files.construction_plan?.show_plan && (
										<FileWithDetails
											plan={true}
											title={intl.formatMessage({ id: 'list.seller.details.estimation.with_attachments' })}
											listId={metadata?.list_id}
											user={user}
											driveFileId={files.construction_plan.id}
											gcsFilePath={files.construction_plan.gcs_file_path}
										/>
									)}

									{files.last_sent_quotation?.show_last_quotation &&
										![QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) && (
											<FileWithDetails
												title={intl.formatMessage({ id: 'Última cotizacion enviada' })}
												listId={metadata?.list_id}
												user={user}
												driveFileId={files.last_sent_quotation.id}
												gcsFilePath={files.last_sent_quotation.gcs_file_path}
												details={[
													{
														key: 'Fecha de cotización:',
														value:
															files.last_sent_quotation.date_loaded &&
															intl.formatDate(files.last_sent_quotation.date_loaded, { timeZone: 'UTC' }),
													},
													{
														key: 'Fecha de vigencia:',
														value:
															files.last_sent_quotation.date_due &&
															intl.formatDate(files.last_sent_quotation.date_due, { timeZone: 'UTC' }),
													},
													{
														key: 'Condiciones ofrecidas:',
														value: (
															<>
																<Box
																	onClick={() => setConditionsOpen(true)}
																	sx={{
																		width: '100%',
																		cursor: 'pointer',
																		fontSize: '1em',
																		color: '#1737B7',
																	}}
																>
																	Ver
																</Box>
																<QuotingConditions
																	open={conditionsOpen}
																	conditions={files.last_sent_quotation.offered_coditions}
																	onClose={() => setConditionsOpen(false)}
																/>
															</>
														),
													},
												]}
											/>
										)}
									{metadata.quotation_comment &&
										[QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) && (
											<Box sx={{ display: 'flex', alignItems: 'center', pl: { md: 4 }, width: '100%' }}>
												<Alert severity="info" sx={{ mt: 2, mr: { md: 4 }, borderRadius: 3, width: '100%' }}>
													<strong>Comentario:</strong> {metadata.quotation_comment}
												</Alert>
											</Box>
										)}
									{metadata.requote_request &&
										metadata.requote_cause_id !== '1' &&
										[QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) && (
											<Box sx={{ display: 'flex', alignItems: 'center', pl: { md: 4 }, width: '100%' }}>
												<Alert severity="info" sx={{ mt: 2, mr: { md: 4 }, borderRadius: 3, width: '100%' }}>
													El pedido de cotización original presenta cambios y se solicita una recotización.
													<br />
													<strong>Motivos:</strong> {metadata.requote_cause_label}
												</Alert>
											</Box>
										)}
									{metadata.requote_request &&
										metadata.requote_cause_id === '1' &&
										[QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) && (
											<Box sx={{ display: 'flex', alignItems: 'center', pl: { md: 4 }, width: '100%' }}>
												<Alert severity="info" sx={{ mt: 2, mr: { md: 4 }, borderRadius: 3, width: '100%' }}>
													El cliente solicitó una extensión de vigencia de la cotización enviada. Si esto no es posible,
													solicitamos una recotización.
												</Alert>
											</Box>
										)}
									{[QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) && (
										<>
											<Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
												{!metadata?.requote_request && (
													<StyledActionButton
														onClick={() => {
															setQuotationDialogOpen(true);
															setExtraPush(true);
														}}
														sx={{
															background: '#1737B7',
															color: '#FFF',
															borderRadius: 10,
															width: '100%',
															maxWidth: { md: '140px' },
														}}
														variant="contained"
													>
														{intl.formatMessage({ id: 'list.seller.details.estimation.quote_button' })}
													</StyledActionButton>
												)}
												{metadata?.requote_request && (
													<StyledActionButton
														onClick={() => {
															setQuotationDialogOpen(true);
															setExtraPush(true);
														}}
														sx={{
															background: '#1737B7',
															color: '#FFF',
															borderRadius: 10,
															width: '100%',
															maxWidth: { md: '140px' },
														}}
													>
														{intl.formatMessage({ id: 'list.seller.details.estimation.re_quote_button' })}
													</StyledActionButton>
												)}
												{metadata?.requote_request && metadata?.requote_cause_id === '1' && (
													<StyledActionButton
														onClick={() => {
															setExtensionDialogOpen(true);
															setExtraPush(true);
														}}
														sx={{
															background: '#1737B7',
															color: '#FFF',
															borderRadius: 10,
															width: '100%',
															maxWidth: { md: '160px' },
														}}
													>
														{intl.formatMessage({ id: 'list.seller.details.estimation.extend_button' })}
													</StyledActionButton>
												)}
												<StyledActionButton
													onClick={() => {
														setRejectionDialogOpen(true);
														setExtraPush(true);
													}}
													variant="outlined"
													sx={{
														color: '#1737B7',
														border: '1px solid #1737B7!important',
														borderRadius: 10,
														width: '100%',
														maxWidth: { md: '140px' },
													}}
												>
													{intl.formatMessage({ id: 'list.seller.details.estimation.reject_button' })}
												</StyledActionButton>
											</Grid>
										</>
									)}
								</>
							)}

							{[QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(quotation.status) &&
								files?.last_sent_quotation?.show_last_quotation &&
								metadata?.requote_request && (
									<>
										{metadata?.requote_cause_id !== '1' && (
											<Accordion
												sx={{ width: '100%', mt: 1, '&:before': { display: 'none' } }}
												elevation={0}
												disableGutters
												defaultExpanded
											>
												<AccordionSummary
													expandIcon={<ExpandMore />}
													aria-controls="panel1a-content"
													id="panel1a-header"
												>
													<Typography variant="h6" sx={{ fontWeight: '500' }}>
														{intl.formatMessage({ id: 'Última cotización enviada' })}
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<FileWithDetails
														title={intl.formatMessage({ id: 'Última cotizacion enviada' })}
														listId={metadata?.list_id}
														user={user}
														driveFileId={files.last_sent_quotation.id}
														gcsFilePath={files.last_sent_quotation.gcs_file_path}
														details={[
															{
																key: 'Fecha de cotización:',
																value:
																	files.last_sent_quotation.date_loaded &&
																	intl.formatDate(files.last_sent_quotation.date_loaded, { timeZone: 'UTC' }),
															},
															{
																key: 'Fecha de vigencia:',
																value:
																	files.last_sent_quotation.date_due &&
																	intl.formatDate(files.last_sent_quotation.date_due, { timeZone: 'UTC' }),
															},
															{
																key: 'Condiciones ofrecidas:',
																value: (
																	<>
																		<Box
																			onClick={() => setConditionsOpen(true)}
																			sx={{
																				width: '100%',
																				cursor: 'pointer',
																				fontSize: '1em',
																				color: '#1737B7',
																			}}
																		>
																			Ver
																		</Box>
																		<QuotingConditions
																			open={conditionsOpen}
																			conditions={files.last_sent_quotation.offered_coditions}
																			onClose={() => setConditionsOpen(false)}
																		/>
																	</>
																),
															},
														]}
													/>
												</AccordionDetails>
											</Accordion>
										)}
										{metadata?.requote_cause_id === '1' && (
											<Accordion
												sx={{ width: '100%', mt: 1, '&:before': { display: 'none' } }}
												elevation={0}
												disableGutters
												defaultExpanded
											>
												<AccordionSummary
													expandIcon={<ExpandMore />}
													aria-controls="panel1a-content"
													id="panel1a-header"
												>
													<Typography variant="h6" sx={{ fontWeight: '500' }}>
														{intl.formatMessage({ id: 'list.seller.details.estimation.title' })}
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<FileWithDetails
														title={intl.formatMessage({ id: 'list.seller.details.estimation.quotation_title' })}
														listId={metadata?.list_id}
														user={user}
														driveFileId={files.quotation_request_file.id}
														gcsFilePath={files.quotation_request_file.gcs_file_path}
														details={
															quotation.status !== QuoteStatusForSeller.NOT_COMPLETED_SALE
																? [
																		{
																			key: intl.formatMessage({ id: 'list.seller.details.estimation.date' }),
																			value:
																				files.quotation_request_file.date_loaded &&
																				intl.formatDate(files.quotation_request_file.date_loaded, { timeZone: 'UTC' }),
																		},
																		{
																			key: intl.formatMessage({ id: 'list.seller.details.estimation.deadline' }),
																			value:
																				files.quotation_request_file.date_due &&
																				intl.formatDate(files.quotation_request_file.date_due, { timeZone: 'UTC' }),
																		},
																		{
																			key: '¿Cotizar envío?',
																			value: 'Sí',
																		},
																  ]
																: [
																		{
																			key: intl.formatMessage({ id: 'list.seller.details.estimation.date' }),
																			value:
																				files.quotation_request_file.date_loaded &&
																				intl.formatDate(files.quotation_request_file.date_loaded, { timeZone: 'UTC' }),
																		},
																  ]
														}
													/>
												</AccordionDetails>
											</Accordion>
										)}
									</>
								)}
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<TableCardTreeView
						loading={!filesResponse}
						title={intl.formatMessage({ id: 'list.seller.details.related_files.title' })}
						layoutColumns={6}
						columns={relatedFiles}
						relatedColumns={relatedFiles}
						rows={allDocuments?.map((document) => ({ ...document, user, list_id: listId }))}
						actions={
							quotation?.can_upload_order_documents
								? [
										{
											displayName: intl.formatMessage({ id: 'list.seller.details.upload_file.action' }),
											icon: <Upload />,
											type: 'Button',
											onClick: () => setFileUploadDialogOpen(true),
										},
								  ]
								: []
						}
					></TableCardTreeView>
				</Grid>
				
				{[QuoteStatusForSeller.IN_DELIVERY_PROCESS, 'Venta finalizada'].includes(quotation.status) && (
					<DeliveryContext.Provider value={{ baseInfo, message, deliveriesCount, setMessage }}>
						<Grid item xs={12}>
							<TableCardTreeView
								loading={!deliveriesResponse}
								title={intl.formatMessage({ id: 'list.seller.details.related_deliveries.title' })}
								layoutColumns={12}
								columns={relatedDeliveries}
								relatedColumns={relatedDeliveryFiles}
								rows={allDeliveries?.map((delivery) => ({
									...delivery,
									list_id: metadata.list_id,
									user,
								}))}
								emptyMessage={intl.formatMessage({ id: 'list.seller.details.related_deliveries.empty_text' })}
								actions={[
									{
										displayName: intl.formatMessage({ id: 'list.seller.details.deliveries.new_delivery.button_label' }),
										icon: <Upload />,
										type: 'Button',
										onClick: () => setNewDeliveryDialogOpen(true),
									},
								]}
							></TableCardTreeView>
						</Grid>
					</DeliveryContext.Provider>
				)}
			</Grid>

			<FileUploadDialog
				open={fileUploadDialogOpen}
				types={allowedFileTypes.filter((fileType) => {
					if ([13, 14, 15].includes(fileType.id)) {
						return (
							allDocuments?.filter(
								(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
							).length > 0
						);
					}

					return true;
				})}
				documents={
					allDocuments?.filter(
						(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
					) || []
				}
				dataSeller={data}
				user={user}
				onClose={(event, reason, newFile) => {
					setFileUploadDialogOpen(false);

					if (newFile) forceInsertNewFile(newFile);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile) fetchListDocuments(quotation?.order_id)
				}}
			/>
			<SellerNewDeliveryDialog
				open={newDeliveryDialogOpen}
				orderId={quotation?.order_id}
				user={user}
				onClose={(event, reason) => {
					setNewDeliveryDialogOpen(false);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchListDeliveries(quotation?.order_id);
				}}
			/>
			<SellerRejectionDialog
				open={rejectionDialogOpen}
				reasons={cancelationReasons}
				quotationId={quoteId}
				listId={metadata.list_id}
				environment={metadata.env_list}
				user={user}
				onClose={(event, reason) => {
					setRejectionDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
			/>
			<SellerExtensionDialog
				open={extensionDialogOpen}
				quotationId={quoteId}
				environment={metadata.env_list}
				user={user}
				onClose={(event, reason) => {
					setExtensionDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
			/>
			<SellerQuotationDialog
				open={quotationDialogOpen}
				environment={metadata.env_list}
				quotationId={quoteId}
				listId={listId}
				user={user}
				onClose={(event, reason) => {
					setQuotationDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
			/>
		</PageContainer>
	);
};

export default DetailsSeller;
