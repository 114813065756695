import axios, { AxiosResponse } from 'axios';

const customAxios = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_N8N_WEBHOOKS_URL,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            config.headers['app_key'] = process.env.REACT_APP_N8N_APP_KEY;

            return config;
        },
        (error) => {
            const customError = {
                method: error.config?.method,
                baseURL: error.config?.baseURL,
                message: error.message,
                status: error.response?.status || "N/A",
                data: error.response?.data || "N/A",
                errors: error.response?.data?.errors || "N/A",
            };
            console.error("Request Error", customError);
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error) => {
            const customError = {
                method: error.config?.method,
                baseURL: error.config?.baseURL,
                message: error.message,
                status: error.response?.status || "N/A",
                data: error.response?.data || "N/A",
                errors: error.response?.data?.errors || "N/A",
            }
            console.error("Response Error", customError);

            return Promise.reject(customError);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const client = {
    parseInvoice: async (invoice: File): Promise<any> => {
        try {
            const client = customAxios();
            const response = await client.postForm('/invoice-parse', {file: invoice});
            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    }
};

export default client;